<template>
  <a-modal
    v-model="show"
    centered
    width="900px"
    title="打印"
  >
    <div ref="print">
      <h1>113.8亿亿次！粤港澳大湾区一体化算力服务平台提供灵活实惠算力资源</h1>
      <div>央视网消息：近日，粤港澳大湾区一体化算力服务平台在深圳前海正式上线试运行。该平台已接入算力资源池8个，汇聚算力规模1138P，也就是每秒计算能力能达到113.8亿亿次，大约相当于59万台家用电脑的算力之和。</div>
      <img src="@/assets/img/404.png" />
      <div>这一算力服务平台将在公共服务、城市治理等场景开放便捷接入的供需对接平台，为各类应用提供灵活实惠的算力资源。</div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="$common.webPrint($refs.print)">打印</a-button>
    </div>
  </a-modal>
</template>
<script>
  export default {
    name: 'webPrint',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      row: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        show: true,
        loading: false,
      }
    },
    watch: {
      value: {
        handler(val) {
          this.show = val
        },
        deep: true,
        immediate: true
      },
      show(val) {
        this.$emit('input', val)
      }
    }
  }
</script>
