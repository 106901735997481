<template>
  <a-modal
    v-model="show"
    :dialog-style="{ top: '20px' }"
    width="900px"
    title="编辑"
    @ok="hideModal"
    @cancel="cancel">
    <a-form-data ref="form" v-model="form" :setting="setting" :render-data="renderData">
      <a-col slot="input2" :span="24">
        <a-form-model-item label="自定义" prop="input2">
          <tinymce v-model="form.input2" :height="300" />
        </a-form-model-item>
      </a-col>
    </a-form-data>
  </a-modal>
</template>
<script>

  export default {
    name: 'DemoForm2',
    props: {
      row: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      const inputValidator = (rule, value, callback) => {
        if (value !== '1') {
          callback('请输入1!');
        } else {
          callback();
        }
      }
      return {
        show: true,
        form: {},
        setting: {
          // labelCol: { span: 3 },
          labelCol: null,
          wrapperCol: null,
          rules: {
            input: [{ required: true, validator: inputValidator, trigger: 'change' }],
            input2: [{ required: true, message: '请', trigger: 'change' }],
            select: [
              { required: true, message: '请选择', trigger: 'change' }
            ],
          }
        },
        renderData: {
          input: {
            label: '输入框',
            span: 6,
            valid: true
          },
          input11: {
            label: '输入框',
            span: 6,
            valid: true
          },
          input12: {
            label: '输入框',
            span: 6,
            valid: true
          },
          input13: {
            label: '输入框',
            span: 6,
            valid: true
          },
          input3:{
            renderType: 'slot'
          },
          // password: {
          //   label: '密码',
          //   renderName: 'aInputPassword'
          // },
          select: {
            label: '选项',
            span: 12,
            valid: true,
            renderName: 'aSelect',
            // setting: {
            //   labelCol: { span: 4 },
            //   wrapperCol: { span: 8 },
            // },
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ]
          },
          aCascader: {
            label: '级联',
            span: 12,
            renderName: 'aCascader',
            options: [
              { label: '一级1', value: 1, children: [ { label: '二级', value: 11 } ] },
              { label: '一级2', value: 2, children: [ { label: '二级1', value: 22 },{ label: '二级2', value: 23 } ] },
            ]
          },
          number: {
            label: '计数',
            span: 12,
            renderName: 'aInputNumber',
            props: { min: 0, max: 10, precision: 2 }
          },
          datePicker: {
            label: '日期',
            renderName: 'aDatePicker'
          },
          rangePicker: {
            label: '选择日期',
            renderName: 'aRangePicker'
          },
          radio: {
            label: '单选',
            renderName: 'aRadioGroup',
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ]
          },
          checkbox: {
            label: '多选',
            renderName: 'aCheckboxGroup',
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ]
          },
          switch: {
            label: '开关',
            renderName: 'aSwitch'
          },
          name: {
            label: '纯文字',
            renderName: 'aText'
          },
          input2: {
            renderType: 'slot'
          },
        }
      }
    },
    methods: {
      async hideModal() {
        try {
          console.log(await this.$refs.form.formRef().validate())
          console.log(this.row)
        } catch (e) {
          console.log('e', e)
        } finally {

        }
      },
      cancel() {
        this.$emit('cancel')
      }
    }
  }
</script>
