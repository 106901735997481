<template>
  <div class="my-content">
    <a-search-form
      ref="searchForm"
      v-model="form"
      :loading="loading"
      :render-data="renderData"
      :setShowIndex="4"
      @handleSearch="handleSearch"
      @handleReset="handleReset">
      <span slot="button">
        <!--<a-button type="primary" icon="plus" @click="show = true">新增</a-button>-->
      </span>
    </a-search-form>
    <div class="p16 pb0">
      <a-space :size="16">
        <a-button type="primary" icon="plus" @click="show = true">表单</a-button>
        <a-button type="primary" icon="plus" @click="show2 = true">表单样式2</a-button>
        <a-button type="primary" icon="gateway" @click="$router.push('publicComponent')">组件</a-button>
        <a-button type="primary" icon="heat-map" @click="$router.push('baiduMap')">地图</a-button>
        <a-button type="primary" icon="apartment" @click="$router.push('graphvis')">关系图谱</a-button>
        <!--<a-button type="primary" icon="apartment" @click="$router.push('xmind')">思维导图</a-button>-->
        <a-button type="primary" icon="printer" @click="showPrint = true">打印</a-button>
      </a-space>
    </div>
    <a-tables
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :setting="setting"
      :page="page"
      @onChange="onChange"
      @onExpand="onExpand"
    >
      <div slot="time2" slot-scope="{ row }">
        {{ row.time | formatTime(false, '{y}-{m}-{d} 星期{a} {h}:{i}:{s}') }}
      </div>
    </a-tables>
    <demo-form v-if="show" :row="row" @cancel="show = false" />
    <demo-form2 v-if="show2" :row="row" @cancel="show2 = false" />
    <webPrint v-if="showPrint" v-model="showPrint" />
  </div>
</template>

<script>
  import DemoForm from "./components/demoForm";
  import DemoForm2 from "./components/demoForm2";
  import axiosQueue from "@/utils/axiosQueue"
  import webPrint from "./components/webPrint";
  export default {
    name: 'demo',
    components: {webPrint, DemoForm, DemoForm2},
    data(){
      const that = this
      return {
        loading:false,
        show: false,
        show2: false,
        showPrint: false,
        form:{},
        row: {
          input: '',
          datePicker: '2022-11-10',
          rangePicker: ['2022-11-10', '2022-11-17']
        },
        renderData: {
          time: {
            renderName: 'aDatePicker',
            label: '选择时间：',
            // labelCol: 7,
            // wrapperCol: 17,
            // span: 5,
          },
          times: {
            renderName: 'aRangePicker',
            label: '选择时间：',
            // labelCol: 6,
            // wrapperCol: 18,
            span: 6,
          },
          year: {
            renderName: 'aYear',
            label: '年份',
            // span: 5,
          },
          input: {
            renderName: 'aInput',
            label: '输入框：'
          },
          cascader: {
            label: '级联',
            valid: true,
            renderName: 'aCascader',
            options: [
              { title: '一级', key: 1 , childrenList: [
                  { title: '二级', key: 2 },
                  { title: '二级2', key: 3 },
                ]
              }
            ],
            props: { changeOnSelect: true, fieldNames: { label: 'title', value: 'key', children: 'childrenList' } }
          },
          select: {
            renderName: 'aSelect',
            label: '网站类型：',
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 }
            ],
            props: { showSearch: true },
            span: 5,
          }
        },
        page: {
          current: 1, pageSize: 20, total: 100
        },
        data: [
          {children: [], name: '神鼎洪諲^嗔恚使^诃罗竭...', value: 1, image: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"},
          {children: [], name: '别婷娴',name2: '34122219',name3: '15658298706',name4: '苯甲酸钠',name5: '山西省长治市城区北京胡同13号', value: 2, time: 1668062192 },
          {children: [], name: 'a', value: 78225.2, tit: 'tit', time: 1690531352},
          {children: [], name: 'a', value: ''},
          {children: [], name: 'a', value: 1},
          {children: [], name: 'a', value: 2},
          {children: [], name: 'a', value: 1},
          {children: [], name: 'a', value: 1},
          {children: [], name: 'a', value: 1},
          {children: [], name: 'a', value: 1},
          {children: [], name: 'a', value: 1}
        ],
        columns: [
          { title: '序号',width: 60, dataIndex: 'index', renderType: 'index'},
          { title: '名称', ellipsis: true, dataIndex: 'name'},
          { title: '域名', dataIndex: 'value'},
          { title: '图片', dataIndex: 'image', renderType: 'image' },
          { title: '可读时间', width: 180, sorter: true, defaultSortOrder: 'descend', dataIndex: 'time', renderType: 'readTime' },
          { title: '自定义/时间', width: 220, sorter: true, dataIndex: 'time2', renderType: 'slot' },
          {
            title: 'html',
            dataIndex: 'html',
            renderType: 'html',
            activeName: 'default',
            default(row, index) {
              // that.$common.copyText(row.name)
              console.log(row, index)
              that.$message.success(row.value)
            },
            render(row) {
              return `<a>${row.value}</a>`
            }
          },
          /*{
            title: 'jsx',
            dataIndex: 'jsx',
            renderType: 'jsx',
            activeName: 'default',
            default(row, index) {
              console.log(row, index)
            },
            render(h, row) {
              return h(`a-button`, 't')
            }
          },*/
          {
            title: '操作',
            width: 220,
            dataIndex: 'handle',
            renderType: 'handle',
            childrens: [
              {
                label: '编辑',
                default(row, index) {
                  that.show = true
                  that.row = row
                  console.log(row, index)
                }
              },
              {
                label: '编辑2',
                hide: (row) => {
                  return row.value !== 2
                },
                default(row, index) {
                  console.log(row, index)
                }
              },
              {
                label: '删除',
                style: 'color: red',
                default(row, index) {
                  console.log(row, index)
                  that.$confirm({
                    title: '提示',
                    content: '确认删除嘛?',
                    // okText: '确定',
                    // okType: 'danger',
                    // cancelText: '取消',
                    onOk() {
                      that.del(row)
                    },
                    // onCancel() {
                    //   console.log('Cancel');
                    // }
                  })
                }
              }
            ],
            dropdown: [
              {
                label: '编辑',
                default(row, index) {
                  that.show = true
                  that.row = row
                  console.log(row, index)
                }
              },
              {
                label: '编辑2',
                hide: (row) => {
                  return row.value !== 2
                },
                default(row, index) {
                  console.log(row, index)
                }
              },
              {
                label: '删除',
                style: 'color: red',
                default(row, index) {
                  console.log(row, index)
                  that.$confirm({
                    title: '提示',
                    content: '确认删除嘛?',
                    // okText: '确定',
                    // okType: 'danger',
                    // cancelText: '取消',
                    onOk() {
                      that.del(row)
                    },
                    // onCancel() {
                    //   console.log('Cancel');
                    // }
                  })
                }
              }
            ],
          }
        ],
        setting: {
          // 开启表格选择
          rowSelection: { onChange: this.onSelectChange }
        }
      }
    },
    created(){
      this.getList()
      // console.log(this.$route)
    },
    methods:{
      async del(row) {
        console.log(row)
      },
      // 表格勾选事件
      onSelectChange(selectedRowKeys, selectedRows) {
        console.log(selectedRowKeys, selectedRows)
      },
      async getList() {
        try {
          this.loading = true
          // const res = await axiosQueue.get('account/profile') // 队列
          // await this.$request({url: 'account/profile', params: { api_token: 1 }})
          // await this.$request({url: 'account/profile', params: { api_token: 1 }})
          // await this.$request({url: 'account/profile', params: { api_token: 1 }})
          // await this.$request({url: 'account/profile', params: { api_token: 1 }})
          // this.$c(res)
        } finally {
          this.loading = false
        }
      },
      // 搜索
      handleSearch() {
        this.page.current = 1
        // this.$c(this.form)
        this.getList()
      },
      // 重置
      handleReset() {
        this.form = {}
        this.getList()
      },
      // 点击分页
      onChange(page, filters, sorter) {
        console.log(page, sorter)
        this.page = page
        this.getList()
      },
      onExpand(expanded, record) {
        for (let i = 0; i < 5; i++) {
          record.children.push(
            {name: 'a', value: 1}
          )
        }
        // console.log(expanded, record)
      }
    }
  }
</script>
