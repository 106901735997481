<template>
  <a-modal
    v-model="show"
    :dialog-style="{ top: '20px' }"
    width="900px"
    title="编辑"
    :confirm-loading="loading"
    @ok="ok"
    @cancel="cancel">
    <a-form-data ref="form" v-model="form" :setting="setting" :render-data="renderData">
      <a-col slot="input2" :span="24">
        <a-form-model-item label="自定义" prop="input2">
          <tinymce v-model="form.input2" :height="300" />
        </a-form-model-item>
      </a-col>
    </a-form-data>
  </a-modal>
</template>
<script>

  export default {
    name: 'DemoForm',
    props: {
      row: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      const inputValidator = (rule, value, callback) => {
        if (value !== '1') {
          callback('请输入1!');
        } else {
          callback();
        }
      }
      return {
        show: true,
        loading: false,
        form: {
          image: 'https://img1.bdstatic.com/static/searchdetail/img/logo-2X_2dd9a28.png',
          images: ['https://img1.bdstatic.com/static/searchdetail/img/logo-2X_2dd9a28.png', 'https://img1.bdstatic.com/static/searchdetail/img/logo-2X_2dd9a28.png']
        },
        setting: {
          // labelCol: { span: 3 },
          rules: {
            input: [{ required: true, validator: inputValidator, trigger: 'change' }],
            input2: [{ required: true, message: '请', trigger: 'change' }],
            select: [
              { required: true, message: '请选择', trigger: 'change' }
            ],
          }
        },
        renderData: {
          input: {
            label: '输入框',
            valid: true,
            events: { change: this.changeInput }
          },
          image: {
            label: '上传图片',
            renderName: 'aUploadImage',
          },
          images: {
            label: '多图上传',
            renderName: 'aUploadImage',
            props: {
              multiple: true, // 多张
              limit: 2, // 最大数量
              // del: true // 是否可以删除
              // disabled: true // 是否禁止上传
            }
          },
          input3:{
            renderType: 'slot'
          },
          year: {
            label: '年份',
            renderName: 'aYear'
          },
          password: {
            label: '密码',
            renderName: 'aInputPassword'
          },
          select: {
            label: '选项',
            valid: true,
            renderName: 'aSelect',
            // setting: {
            //   labelCol: { span: 4 },
            //   wrapperCol: { span: 8 },
            // },
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ],
          },
          aTreeSelect: {
            label: '树型选择',
            renderName: 'aTreeSelect',
            treeData: [
              { id: 1, name: '一级1'},
              { id: 2, name: '一级2',
                sub: [
                  { id: 3, name: '二级1' },
                  { id: 4, name: '二级2',
                    sub: [
                      { id: 5, name: '三级1' },
                      { id: 6, name: '三级2' }
                    ]
                  },
                ]
              },
            ]
          },
          aCascader: {
            label: '级联',
            renderName: 'aCascader',
            options: [
              { label: '一级1', value: 1, children: [ { label: '二级', value: 11 } ] },
              { label: '一级2', value: 2, children: [ { label: '二级1', value: 22 },{ label: '二级2', value: 23 } ] },
            ]
          },
          number: {
            label: '计数',
            renderName: 'aInputNumber',
            props: { min: 0, max: 10, precision: 2 }
          },
          datePicker: {
            label: '日期',
            renderName: 'aDatePicker'
          },
          rangePicker: {
            label: '选择日期',
            renderName: 'aRangePicker'
          },
          radio: {
            label: '单选',
            renderName: 'aRadioGroup',
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ]
          },
          checkbox: {
            label: '多选',
            renderName: 'aCheckboxGroup',
            options: [
              { label: '选项一', value: 1 },
              { label: '选项二', value: 2 },
              { label: '选项三', value: 3 },
            ]
          },
          switch: {
            label: '开关',
            renderName: 'aSwitch'
          },
          name: {
            label: '纯文字',
            renderName: 'aText'
          },
          input2: {
            renderType: 'slot'
          },
        }
      }
    },
    methods: {
      changeInput(val) {
        console.log(val)
      },
      async ok() {
        try {
          this.loading = true
          console.log(this.form)
          await this.$refs.form.formRef().validate()
        } finally {
          this.loading = false
        }
      },
      cancel() {
        this.$emit('cancel')
      }
    }
  }
</script>
